<template>
  <div style="height: inherit">
    <Modal
      @close="closeAddModal"
      v-if="showAddModal"
      :show="showAddModal"
      :title="text.add_mt_title[language]"
    >
      <template slot="body">
        <div class="block block__column block__between" style="height: 100%; padding: 10px">
          <div style="height: 100%; overflow-y: auto">
            <span class="component__margin-bottom">
              {{ text.adding_text[language] }}
              <a
                style="text-decoration: underline"
                :href="
                  `mailto:support@i5.solutions?body=${text.add_mail_body[language]} ` +
                  `&subject=${text.add_mail_subject[language]} `
                "
              >
                {{ text.adding_text_2[language] }}
              </a>
            </span>
            <div class="block">
              <Input
                :label="text.serial_number[language]"
                :error="snError"
                style="width: 40%"
                required
                @change="saveAdd($event, 'serial_number')"
              />
              <Search
                style="width: 100%"
                :error="mtError"
                :position-type="'fixed'"
                :label="text.machine_tool_name[language]"
                required
                class="component__margin-left"
                @change="setMt($event)"
                :dropdown-props="{
                  img: 'img',
                  sublabel: 'manufacturing',
                  secondLine: 'machine_tool_method',
                }"
                :query="{
                  method: 'post',
                  url: 'getSearchMachineTools',
                  findName: 'name',
                  label: 'name',
                  value: 'id',
                }"
              />
            </div>
            <div class="block">
              <Input
                disabled
                style="width: 100%"
                :label="text.manufacturing[language]"
                :default-value="addCompanyName"
              />
              <Input
                class="component__margin-left"
                style="width: 100%"
                disabled
                :label="text.machine_tool_method[language]"
                :default-value="addMethod"
              />
            </div>
            <div class="block">
              <Select
                :values="shops"
                :error="shError"
                style="width: 100%"
                @change="saveAdd($event.value, 'id_shop')"
                required
                :label="text.shop_name[language]"
                :dropdown-props="{
                  label: 'name',
                  value: 'id',
                }"
                :position-type="'fixed'"
              />
              <Select
                class="component__margin-left"
                style="width: 100%"
                position-type="fixed"
                :error="acError"
                required
                :label="text.accuracy_class[language]"
                :values="accuracyClasses"
                @change="saveAdd(+$event.value, 'id_current_accuracy_class')"
                :dropdown-props="{
                  label: 'name',
                  value: 'id',
                }"
              />
            </div>
          </div>
          <div style="padding: 10px" class="block block__right">
            <Button type="default" @click="closeAddModal" :text="text.cancel[language]" />
            <Button
              type="primary"
              :text="text.save[language]"
              @click="addMachineTool"
              style="margin-left: 10px"
            />
          </div>
        </div>
      </template>
    </Modal>
    <PageConstructor
      :form-name="['serial_number', 'machine_tool_name']"
      @setUpdate="setUpdate($event)"
      :query="query"
      :buttons="buttons"
      show-filter
      @headerClick="headerClick($event)"
      :page-name="text.page_name[language]"
      :columns="columns"
      :add-title="text.add_mt[language]"
      :query-filter="queryFilter"
    >
      <template slot="edit" slot-scope="{ row }">
        <Edit :row="row" @update="updateTable" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/machine_tools.json";
import Modal from "@/components/i5Modal/I5Modal.vue";
import Search from "@/components/search/Search.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
import Select from "@/components/select/Select.vue";
import { query, requestFile } from "@/utilities/axios";
import fileSaver from "file-saver";
// import Add from './components/add/Add.vue';
import Edit from "./components/edit/Edit.vue";

export default {
  name: "MachineTools",
  data() {
    return {
      text,
      fileSaver,
      serialNumber: null,
      idMachineTool: null,
      idShop: null,
      idAccClass: null,
      snError: null,
      mtError: null,
      shError: null,
      acError: null,
      tableUpdate: null,
      showAddModal: false,
      addCompanyName: "",
      addMethod: "",
      shops: [],
      accuracyClasses: [],
      query: {
        method: "post",
        url: "getCurrentCompaniesMachineToolsV2",
        headers: {},
        data: {},
      },
      queryFilter: {
        method: "post",
        url: "getOrdersFiltersAdminV2",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "get_new_orders",
          label: "",
          type: "status",
          options: {
            sort: true,
          },
          basis: 50,
          width: 50,
        },
        {
          name: "serial_number",
          label: text.serial_number,
          options: {
            bold: true,
            sort: true,
          },
          form: {
            type: "input",
          },
          basis: 150,
          width: 150,
        },
        {
          name: "machine_tool_name",
          label: text.machine_tool_name,
          options: {
            bold: true,
            sort: true,
          },
          form: {
            type: "search",
          },
          basis: 200,
          width: 200,
        },
        {
          name: "machine_tool_method",
          label: text.machine_tool_method,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
        {
          name: "manufacturing",
          label: text.manufacturing,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
        {
          name: "shop_name",
          label: text.shop_name,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "labor_hour_price",
          label: text.labor_hour_price,
          basis: 150,
          width: 150,
          type: "money",
          options: {
            bold: true,
            sort: true,
          },
        },
        {
          name: "id_operator",
          label: text.id_operator,
          options: {
            formOnly: true,
          },
          basis: 150,
          width: 150,
        },
      ],
      buttons: [
        {
          type: "download",
        },
        {
          type: "main",
          text: text.add_mt[this.$store.state.index.language],
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.openAddModal();
      } else if (type === "download") {
        this.downloadList();
      }
    },
    openAddModal() {
      if (this.shops.length === 0) {
        this.getShops();
        this.getAccuracyClasses();
      }
      this.showAddModal = true;
    },
    closeAddModal() {
      this.addCompanyName = null;
      this.addMethod = null;
      this.showAddModal = false;
    },
    setMt(value) {
      this.addCompanyName = value.company_name || "";
      this.addMethod = value.method || "";
      this.saveAdd(value.value, "id_machine_tool");
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    updateTable() {
      this.tableUpdate();
    },
    addMachineTool() {
      if (!this.serialNumber) {
        this.snError = text.req[this.language];
      }
      if (!this.idMachineTool) {
        this.mtError = text.req[this.language];
      }
      if (!this.idShop) {
        this.shError = text.req[this.language];
      }
      if (!this.idAccClass) {
        this.acError = text.req[this.language];
      }
      if (!this.snError && !this.mtError && !this.shError && !this.acError) {
        const data = {
          id_machine_tool: this.idMachineTool,
          id_shop: this.idShop,
          serial_number: this.serialNumber,
          id_current_accuracy_class: this.idAccClass,
        };
        query("put", "addMachineToolToCompany", data, {
          token: localStorage.getItem("token"),
        })
          .then(() => {
            this.closeAddModal();
            this.tableUpdate();
            this.idShop = null;
            this.idMachineTool = null;
            this.serialNumber = null;
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 409) {
                this.snError = text.snError[this.language];
              }
            }
          });
      }
    },
    getShops() {
      query(
        "get",
        "getCurrentCompaniesShops",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((shops) => {
        this.shops = shops;
      });
    },
    saveAdd(value, name) {
      if (name === "serial_number") {
        this.snError = null;
        this.serialNumber = value;
      } else if (name === "id_machine_tool") {
        this.mtError = null;
        this.idMachineTool = value;
      } else if (name === "id_shop") {
        this.shError = null;
        this.idShop = value;
      } else if (name === "id_current_accuracy_class") {
        this.acError = null;
        this.idAccClass = value;
      }
    },
    downloadList() {
      requestFile(
        "get",
        "expMachineToolsToExcel",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((item) => {
        const name = item.data.headers["content-disposition"];
        const VRegExp = new RegExp(/[^]*filename="([^]*)"/);
        const VResult = name.replace(VRegExp, "$1");
        this.fileSaver.saveAs(item.data.data, VResult);
      });
    },
    getAccuracyClasses() {
      query(
        "get",
        "getAccuracyClasses",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.accuracyClasses = res;
      });
    },
  },
  components: {
    PageConstructor,
    // Add,
    Edit,
    Modal,
    Search,
    Input,
    Select,
    Button,
  },
};
</script>
