var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"inherit"}},[(_vm.showAddModal)?_c('Modal',{attrs:{"show":_vm.showAddModal,"title":_vm.text.add_mt_title[_vm.language]},on:{"close":_vm.closeAddModal}},[_c('template',{slot:"body"},[_c('div',{staticClass:"block block__column block__between",staticStyle:{"height":"100%","padding":"10px"}},[_c('div',{staticStyle:{"height":"100%","overflow-y":"auto"}},[_c('span',{staticClass:"component__margin-bottom"},[_vm._v(" "+_vm._s(_vm.text.adding_text[_vm.language])+" "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":`mailto:support@i5.solutions?body=${_vm.text.add_mail_body[_vm.language]} ` +
                `&subject=${_vm.text.add_mail_subject[_vm.language]} `}},[_vm._v(" "+_vm._s(_vm.text.adding_text_2[_vm.language])+" ")])]),_c('div',{staticClass:"block"},[_c('Input',{staticStyle:{"width":"40%"},attrs:{"label":_vm.text.serial_number[_vm.language],"error":_vm.snError,"required":""},on:{"change":function($event){return _vm.saveAdd($event, 'serial_number')}}}),_c('Search',{staticClass:"component__margin-left",staticStyle:{"width":"100%"},attrs:{"error":_vm.mtError,"position-type":'fixed',"label":_vm.text.machine_tool_name[_vm.language],"required":"","dropdown-props":{
                img: 'img',
                sublabel: 'manufacturing',
                secondLine: 'machine_tool_method',
              },"query":{
                method: 'post',
                url: 'getSearchMachineTools',
                findName: 'name',
                label: 'name',
                value: 'id',
              }},on:{"change":function($event){return _vm.setMt($event)}}})],1),_c('div',{staticClass:"block"},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"disabled":"","label":_vm.text.manufacturing[_vm.language],"default-value":_vm.addCompanyName}}),_c('Input',{staticClass:"component__margin-left",staticStyle:{"width":"100%"},attrs:{"disabled":"","label":_vm.text.machine_tool_method[_vm.language],"default-value":_vm.addMethod}})],1),_c('div',{staticClass:"block"},[_c('Select',{staticStyle:{"width":"100%"},attrs:{"values":_vm.shops,"error":_vm.shError,"required":"","label":_vm.text.shop_name[_vm.language],"dropdown-props":{
                label: 'name',
                value: 'id',
              },"position-type":'fixed'},on:{"change":function($event){return _vm.saveAdd($event.value, 'id_shop')}}}),_c('Select',{staticClass:"component__margin-left",staticStyle:{"width":"100%"},attrs:{"position-type":"fixed","error":_vm.acError,"required":"","label":_vm.text.accuracy_class[_vm.language],"values":_vm.accuracyClasses,"dropdown-props":{
                label: 'name',
                value: 'id',
              }},on:{"change":function($event){return _vm.saveAdd(+$event.value, 'id_current_accuracy_class')}}})],1)]),_c('div',{staticClass:"block block__right",staticStyle:{"padding":"10px"}},[_c('Button',{attrs:{"type":"default","text":_vm.text.cancel[_vm.language]},on:{"click":_vm.closeAddModal}}),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","text":_vm.text.save[_vm.language]},on:{"click":_vm.addMachineTool}})],1)])])],2):_vm._e(),_c('PageConstructor',{attrs:{"form-name":['serial_number', 'machine_tool_name'],"query":_vm.query,"buttons":_vm.buttons,"show-filter":"","page-name":_vm.text.page_name[_vm.language],"columns":_vm.columns,"add-title":_vm.text.add_mt[_vm.language],"query-filter":_vm.queryFilter},on:{"setUpdate":function($event){return _vm.setUpdate($event)},"headerClick":function($event){return _vm.headerClick($event)}},scopedSlots:_vm._u([{key:"edit",fn:function({ row }){return [_c('Edit',{attrs:{"row":row},on:{"update":_vm.updateTable}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }