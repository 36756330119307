<template>
  <div class="block__flex block__align-center block__column" style="position: relative">
    <div style="width: 100%" :class="`input-i5 ${setFocus()}`">
      <label :for="name" @click="handleFocusOut"
        >{{ label }}
        <span v-if="required">*</span>
        <span v-if="subLabel && !error" class="input-i5__sublabel">
          {{ subLabel }}
        </span>
        <span v-if="error" class="input-i5__error">
          {{ error }}
        </span>
      </label>
      <div style="position: relative">
        <input
          :tabindex="ti"
          :class="`${error ? 'input-i5_error' : ''}`"
          @focus="handleFocus"
          @blur="handleFocusOut"
          @click="handleInputClick"
          :value="value"
          :id="name"
          :name="name"
          autocomplete="off"
          readonly
          :disabled="disabled === true"
          :style="`${
            clearable ? 'padding-right: 20px;' : ''
          } height: ${height}px !important; margin-bottom: ${marginBottom}px !important; ${
            maxWidth ? `max-width: ${maxWidth}px;` : ''
          }`"
          @input="handleChange"
        />
        <ClearImage v-if="clearable && value" class="input-i5__clear" @click="clear" />
      </div>
    </div>
    <div
      :id="`${name}_dropdown`"
      :style="{
        width: `${elWidth}px`,
        maxHeight: `${dropDownMaxHeight}px`,
        marginTop: `${setMarginTop()}`,
        position: `${setPosition()}`,
        bottom: `${setBottom()}`,
        top: `${setTop()}`,
        zIndex: `1`,
      }"
      :class="`search__container ${setOpen()}`"
    >
      <div
        v-for="(val, index) in dropdownValues"
        v-bind:key="`dd_${index}`"
        @mousedown="
          (e) => {
            e.preventDefault();
          }
        "
      >
        <div
          @click="setElement(val)"
          :class="`search__item block block__align-center ${val && val.secondLine && 'search__item_double'}`"
        >
          <img
            class="component__margin-right"
            v-if="dropdownProps && dropdownProps.img && val[dropdownProps.img]"
            :src="val[dropdownProps.img]"
          />
          <div>
            <div class="block block__align-center">
              <div>{{ val.label }}</div>
              <div v-if="val.sublabel" class="component__margin-left">({{ val.sublabel }})</div>
            </div>
            <div v-if="val.secondLine">
              {{ val.secondLine }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClearImage from "@/assets/images/form/clear.svg";

export default {
  name: "Search",
  data() {
    return {
      focus: false,
      open: false,
      elWidth: null,
      dropDownMaxHeight: 100,
      dropDownDirection: "bottom", // top
      dropdownTransform: 0,
      value: "",
      dropdownValues: [],
      isTopPosition: false,
    };
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
    name: {
      type: String,
      default: "search",
    },
    defaultValue: {
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
    },
    placement: {
      type: String,
      validator: function (value) {
        return ["bottomLeft", "bottom", "bottomRight"].indexOf(value) !== -1;
      },
    },
    positionType: {
      type: String,
      default: "scrollable", // fixed
    },
    active: [Number, String, Object],
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dropdownProps: {
      type: Object, // label, value, img, sublabel, secondLine
    },
    height: {
      type: Number,
      default: 35,
    },
    marginBottom: {
      type: Number,
      default: 15,
    },
    maxWidth: {
      type: Number,
    },
  },
  watch: {
    active: function () {
      this.open = false;
    },
    defaultValue: function (val) {
      this.value = val;
    },
    values: function (val) {
      this.setDropdownValues(val);
    },
  },
  computed: {
    elHeight() {
      return this.label ? this.height + 35 : this.height + 3;
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    handleFocus() {
      this.open = true;
      this.focus = true;
    },
    handleFocusOut() {
      this.open = false;
      this.focus = false;
    },
    handleInputClick() {
      if (!this.open && !this.disabled && this.focus) {
        this.open = true;
      }
    },
    setFocus() {
      if (this.focus) {
        return "search__focus";
      }
      return "";
    },
    setOpen() {
      let result = "";
      if (this.open) {
        result = "search__open";
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          const dropdownWidth = dropdown.offsetWidth;
          const x = dropdown.getBoundingClientRect().x;
          const width = window.innerWidth;
          if (width / 2 < x && x + dropdownWidth > width) {
            this.dropdownTransform = `-${Math.round(x + dropdownWidth - width + 8)}px`;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          } else if (this.placement === "bottomLeft") {
            this.dropdownTransform = this.$el.getBoundingClientRect().x - x;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          }
          const { bottom } = this.$el.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          this.isTopPosition = bottom + 100 > windowHeight;
          this.dropdownToggle();
        });
      }
      return result;
    },
    dropdownToggle() {
      const y = this.$el.getBoundingClientRect().y;
      if (!this.isTopPosition) {
        this.dropDownMaxHeight = window.innerHeight - y - 90;
      } else {
        this.dropDownMaxHeight = y - 90;
      }
      // console.log('bottom', window.innerHeight - y - 30);
    },
    clear() {
      this.value = "";
      this.$emit("clear");
    },
    setPosition() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = "absolute";
      } else if (this.positionType === "fixed") {
        result = "fixed";
      }
      return result;
    },
    setMarginTop() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = `-${this.marginBottom - 2}px`;
      } else if (this.positionType === "fixed") {
        const labelHeight = this.label ? 35 : 0;
        if (!this.isTopPosition) {
          result = `${this.height + labelHeight}px`;
        } else {
          result = `-${this.dropDownMaxHeight}px`;
        }
      }
      return result;
    },
    setBottom() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = this.isTopPosition ? "100%" : "unset";
      } else if (this.positionType === "fixed") {
        result = "unset";
      }
      return result;
    },
    setTop() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = !this.isTopPosition ? "100%" : "unset";
      } else if (this.positionType === "fixed") {
        result = "unset";
      }
      return result;
    },
    handleChange(evt) {
      this.value = evt.target.value;
      const input = document.getElementById(`${this.name}`);
      input.blur();
    },
    setDropdownValues(val) {
      const values = [];
      val.forEach((item) => {
        const result = {};
        if (this.dropdownProps.label && item[this.dropdownProps.label]) {
          result.label = item[this.dropdownProps.label];
        }
        if (this.dropdownProps.value && item[this.dropdownProps.value]) {
          result.value = item[this.dropdownProps.value];
        }
        if (this.dropdownProps.img && item[this.dropdownProps.img]) {
          result.img = item[this.dropdownProps.img];
        }
        if (this.dropdownProps.sublabel && item[this.dropdownProps.sublabel]) {
          result.sublabel = item[this.dropdownProps.sublabel];
        }
        if (this.dropdownProps.secondLine && item[this.dropdownProps.secondLine]) {
          result.secondLine = item[this.dropdownProps.secondLine];
        }
        values.push(result);
      });

      this.dropdownValues = values;
    },
    setElement(val) {
      this.value = val.label;
      this.$emit("change", val);
      this.open = false;
      // this.focus = false;
      document.getElementById(`${this.name}`).blur();
    },
  },
  mounted() {
    this.elWidth = this.$el.scrollWidth;
    this.value = this.defaultValue;
    if (this.values) {
      this.setDropdownValues(this.values);
    }
  },
  components: {
    ClearImage,
  },
};
</script>
