<template>
  <div class="order__wrapper">
    <Modal
      :title="text.sending_order[language]"
      :ok-disabled="!(checkData && checkData.check)"
      :show="showSendModal"
      :ok-text="text.send_ok"
      @ok="closeSendModal"
      @cancel="closeSendModal"
    >
      <template slot="body">
        <div class="order__success-check">
          <div
            v-if="
              !checkData ||
              (checkData['important_errors'].length === 0 &&
                checkData['recommendations'].length === 0)
            "
            class="order__success-check"
          >
            <div>
              <success-image />
            </div>
            <div class="component__margin-left" v-if="orderData && orderData.name">
              {{ text.success_check[language] }} {{ orderData.name }}
            </div>
          </div>
          <div v-if="checkData">
            <div v-if="checkData['important_errors'] && checkData['important_errors'].length > 0">
              <div class="block__flex">
                <WarningImage class="order__check_danger" />
                <div class="order__check_title component__margin-left">
                  {{ text.important_errors[language] }}
                </div>
              </div>
              <div
                v-for="(item, index) in checkData['important_errors']"
                v-bind:key="`ie_${index}`"
              >
                <div class="order__check_sub-title">
                  {{ item.title }}
                </div>
                <ul v-for="(el, index) in item.messages" v-bind:key="`iem_${index}`">
                  <li>{{ el }}</li>
                </ul>
              </div>
            </div>
            <div v-if="checkData['recommendations'] && checkData['recommendations'].length > 0">
              <div class="block__flex">
                <WarningImage class="order__check_rec" />
                <div class="order__check_title component__margin-left">
                  {{ text.recommendations[language] }}
                </div>
              </div>
              <div
                v-for="(item, index) in checkData['recommendations']"
                v-bind:key="`rec_${index}`"
              >
                <div class="order__check_sub-title">
                  {{ item.title }}
                </div>
                <ul v-for="(el, index) in item.messages" v-bind:key="`rm_${index}`">
                  <li>{{ el }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :title="text.link[language]"
      :show="showLinkModal"
      :ok-text="text.link_ok"
      :show-cancel="false"
      @ok="closeLinkModal"
      @cancel="closeLinkModal"
    >
      <template slot="body">
        <div>
          <div class="block block__align-center">
            <div class="component__margin-right">
              <a-switch
                v-if="orderData && orderData.share"
                :default-checked="orderData.share"
                @change="changeAccessByLink(!orderData.share)"
              />
            </div>
            <div v-if="orderData && orderData.share">{{ text.access_on[language] }}</div>
            <div v-if="orderData && !orderData.share">{{ text.access_off[language] }}</div>
          </div>
          <div class="order__share_success-copy">
            {{ successCopy ? text.success_copy[language] : "" }}
          </div>
          <div class="block">
            <Input
              style="width: 100%"
              :disabled="orderData && !orderData.share"
              :default-value="`https://app.i5.solutions/view-order/${$route.params.id}`"
            />
            <Button
              @click="copyText(`https://app.i5.solutions/view-order/${$route.params.id}`)"
              size="small"
              :disabled="orderData && !orderData.share"
              class="component__margin-left"
              :text="text.copy[language]"
            />
          </div>
        </div>
      </template>
    </Modal>
    <SelectCompany
      @update="getOrder($route.params.id)"
      :show="showSelectCompanyModal"
      :uuid="$route.params.id"
      @close="closeSelectCompanyModal"
    />
    <KpModal :show="showKpModal" @close="closeKpModal" />
    <Modal
      :title="text.refuse_title[language]"
      :show="showRefuseModal"
      :ok-text="text.ok_refuse"
      @ok="refuseOrder"
      @cancel="closeRefuseModal"
    >
      <template slot="body">
        <Select
          :values="cancelReasons"
          required
          :error="reasonError"
          style="width: 100%"
          @change="saveRefuseData(+$event.value, 'id_decline_order_reason')"
          :dropdown-props="{
            label: 'text',
            value: 'id',
          }"
          :position-type="'fixed'"
          :label="text.cause_type[language]"
        />
        <Textarea
          :label="text.describe_reason[language]"
          @change="saveRefuseData($event, 'decline_reason_comment')"
          style="width: 100%"
        />
      </template>
    </Modal>
    <div class="order__container" v-if="orderData">
      <Button
        @click="goToEdit"
        style="margin-bottom: 20px"
        block
        v-if="orderData && orderData.edit"
        :text="text.back_to_edit[language]"
      />
      <div class="block block__between block__align-center">
        <div class="block block__align-center" style="margin-bottom: 20px">
          <div class="order__order">{{ text.order[language] }}</div>
          <div class="order__status">{{ orderData.status }}</div>
        </div>
        <div
          v-if="access && access.admin"
          @click="openLinkModal"
          class="component__margin-left component__margin-bottom order__share_container"
        >
          <Share />
        </div>
      </div>
      <div class="order__label order__block component__select" v-if="orderData.name">
        № {{ orderData.name }}
      </div>
      <a
        v-if="orderData.name"
        :href="`${PackageJson.url}getOrderArchive?uuid=${$route.params.id}&t=${token}`"
        :download="`${orderData.name}`"
      >
        <Button v-if="path === 'order'" block :text="text.download_files[language]" />
      </a>
      <Button
        v-if="path === 'view-order'"
        @click="openAuth"
        block
        :text="text.download_files[language]"
      />
      <div class="order__block">
        <div class="order__label">{{ text.timing[language] }}</div>
        <div class="component__margin-top">{{ text.deadline[language] }}</div>
        <DropdownV3
          name="calendar_1"
          @setClose="setDateAppClose($event)"
          :disabled="!(orderData && orderData.edit)"
        >
          <template slot="body">
            <div class="block block__align-center component__margin-top">
              <CalendarImage />
              <div class="component__margin-left order__time">
                {{
                  orderData.date_applications ? orderData.date_applications : text.not_set[language]
                }}
              </div>
            </div>
          </template>
          <template slot="dropdown">
            <Calendar @setDate="saveDateApp($event)" :default-date="orderData.date_applications" />
          </template>
        </DropdownV3>
        <div class="component__margin-top">{{ text.execution_time[language] }}</div>
        <DropdownV3
          name="calendar_1"
          :disabled="!(orderData && orderData.edit)"
          @setClose="setDateExecClose($event)"
        >
          <template slot="body">
            <div class="block block__align-center component__margin-top">
              <CalendarImage />
              <div class="component__margin-left order__time">
                {{ orderData.date_execution ? orderData.date_execution : text.not_set[language] }}
              </div>
            </div>
          </template>
          <template slot="dropdown">
            <Calendar @setDate="saveDateExec($event)" :default-date="orderData.date_execution" />
          </template>
        </DropdownV3>
      </div>
      <div class="order__block">
        <div class="order__label">{{ text.general_details[language] }}</div>
        <div class="order__general-details">
          <div class="block component__padding-bottom" v-if="orderData.parts">
            <PartImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.type_of_parts[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.parts) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom" v-if="orderData.total_parts">
            <BoxImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_parts[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_parts) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom" v-if="orderData.total_files">
            <FileImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_files[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_files) }} </span>
              <span>{{ text.pcs[language] }}</span>
            </span>
          </div>
          <div class="block component__padding-bottom" v-if="orderData.total_weight">
            <WeightImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.total_weight[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span>{{ getNumberWithSpaces(orderData.total_weight) }} </span>
              <span>{{ text.kg[language] }}</span>
            </span>
          </div>
          <div
            class="block component__padding-bottom"
            v-if="orderData.max_price || orderData.max_price === null"
          >
            <CoinsImage class="component__margin-right" />
            <div class="order__general-details_label">{{ text.max_price[language] }}:</div>
            <span class="order__general-details_value component__margin-left">
              <span v-if="orderData.max_price"
                >{{ getNumberWithSpaces(orderData.max_price) }}
              </span>
              <span v-if="!orderData.max_price">{{ text.not_set[language] }} </span>
              <span v-if="orderData.max_price">{{ text.rub[language] }}</span>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="order__block">
        <div class="order__label">Общая цена за заказ</div>
        <Input name="total_price" />
      </div> -->
      <div class="order__block" v-if="orderData.edit">
        <div class="order__label">{{ text.max_price[language] }}</div>
        <Input
          currency
          @change="changeOrder('max_price', +$event)"
          type="number"
          :default-value="orderData && orderData.max_price ? orderData.max_price : ''"
          class="component__margin-top"
        />
      </div>
      <div class="order__block">
        <div class="order__label">{{ text.comment[language] }}</div>
        <div
          class="order__comment order__general-details component__select"
          v-if="!orderData.edit && orderData.comment"
        >
          {{ orderData.comment }}
        </div>
        <Textarea
          @change="changeOrder('comment', $event)"
          :default-value="orderData && orderData.comment ? orderData.comment : ''"
          class="component__margin-top"
          v-if="orderData.edit"
        />
      </div>
      <div
        v-if="orderData && orderData.insert_price && orderData.status !== 4"
        class="page__edit_margin"
      >
        <div class="order__label component__margin-bottom">
          {{ text.estimated_order_value[language] }}
        </div>
        <div>
          <div class="block">
            <a-radio
              :disabled="!priceRecommendations"
              :checked="orderPriceType === 1"
              @change="setOrderPriceType(1)"
            >
            </a-radio>
            <div>{{ text.recommend_price[language] }}</div>
          </div>
          <div class="block">
            <a-radio :checked="orderPriceType === 2" @change="setOrderPriceType(2)"> </a-radio>
            <div>
              {{ text.order_price_for_one[language] }}
            </div>
          </div>
          <div class="block">
            <a-radio :checked="orderPriceType === 3" @change="setOrderPriceType(3)"> </a-radio>
            <div>
              {{ text.order_total_price[language] }}
            </div>
          </div>
        </div>
        <div v-if="orderPriceType === 2" class="order__cost">
          <div class="order__cost_title component__margin-bottom">
            {{ text.order_cost[language] }}
          </div>
          <div class="order__cost_value">
            <span>
              <span>
                {{ getMoney(orderCost, "RUB", language) }}
              </span>
              <span class="order__cost_text" v-if="kpData && kpData.kp_agency && !orderData.inner">
                ({{ text.agency_i5_2[language] }}
                <span class="order__cost_value">
                  {{ getMoney(kpData.kp_agency, "RUB", "ru") }}
                </span>
                <span class="order__cost_text"> ) </span>
              </span>
            </span>
          </div>
        </div>
        <div v-if="orderPriceType === 1 && recommendations" class="order__cost">
          <div class="order__cost_title component__margin-bottom">
            {{ text.order_cost[language] }}
          </div>
          <div
            class="order__cost_value"
            v-if="recommendations && recommendations.price_recommendation"
          >
            <span>
              <span>
                {{ getMoney(recommendations.price_recommendation, "RUB", language) }}
              </span>
              <span
                class="order__cost_text"
                v-if="recommendations.price_agency && !orderData.inner"
              >
                <span> ({{ text.agency_i5_2[language] }} </span>
                <span class="order__cost_value">
                  {{ getMoney(recommendations.price_agency, "RUB", "ru") }}
                </span>
                <span class="order__cost_text"> ) </span>
              </span>
            </span>
          </div>
        </div>
        <div v-if="orderPriceType === 3">
          <Input
            type="number"
            @change="saveFullPrice($event)"
            :label="text.order_cost[language]"
            :sub-label="text.rub[language]"
            :default-value="orderCost"
          />
          <div
            v-if="kpData && kpData.kp_agency && !orderData.inner"
            class="component__margin-bottom"
          >
            <span class="order__cost_text">
              {{ text.agency_i5_2[language] }}
              <span class="order__cost_value">
                {{ getMoney(kpData.kp_agency, "RUB", "ru") }}
              </span>
            </span>
          </div>
        </div>
        <Button
          class="component__margin-bottom"
          @click="openKpModal"
          block
          type="attention"
          :text="text.create_kp[language]"
        />
        <Button
          class="component__margin-bottom"
          @click="openRefuseModal"
          block
          :text="text.cancel_order[language]"
        />
      </div>
      <!--Интерфейс калькулятора для тех кто не оплатил-->
      <!--<div style="margin-bottom: 40px">
        <div class="order__label component__margin-bottom">
          Изготовление на своем производстве
        </div>
        <div class="component__margin-bottom">
          Вы можете оценить стоимость заказа на своем производстве и сформировать КП.
        </div>
        <Button
          block
          type="attention"
          icon="calculator"
          text="Рассчитать стоимость" />
      </div>-->
      <div
        class="page__edit_margin"
        v-if="orderData && orderData.edit && orderData.id_status === 11"
      >
        <div class="order__label component__margin-bottom">
          {{ text.find_manufacturer[language] }}
        </div>
        <div class="component__margin-bottom">
          {{ text.find_manufacturer_text[language] }}
        </div>
      </div>
      <div
        class="page__edit_margin"
        v-if="
          (orderData && orderData.count_manuf && orderData.count_manuf !== '0') ||
          (orderData &&
            orderData.count_manuf &&
            orderData.count_manuf === '0' &&
            success &&
            success.admin)
        "
      >
        <div class="order__label component__margin-bottom">
          {{ text.offers_on_order_title[language] }}
        </div>
        <div class="component__margin-bottom">
          <span
            >{{ text.offers_on_order_text_1[language] }} <b>{{ orderData.count_manuf }}</b>
            {{ text.offers_on_order_text_2[language] }}
          </span>
        </div>
        <Button
          block
          type="attention"
          @click="seeOffers"
          :text="`${text.offers_on_order_button[language]} ( ${orderData.count_manuf} )`"
        />
      </div>
      <div
        style="margin-top: 15px"
        class="page__edit_margin"
        v-if="orderData && !orderData.count_manuf && kpData && kpData.total_price"
      >
        <div class="order__label component__margin-bottom">
          {{ text.order_evaluated[language] }}
        </div>
        <div class="order__price">
          <span>
            {{ getMoney(kpData.total_price, "RUB", "ru") }}
          </span>
        </div>
      </div>
      <Button
        v-if="
          orderData && orderData.edit && (orderData.id_status === 12 || orderData.id_status === 9)
        "
        block
        type="attention"
        class="page__edit_margin"
        @click="openSelectCompanyModal"
        :text="text.select_company[language]"
      />
      <Button
        v-if="orderData.inner"
        block
        type="attention"
        @click="checkOrder"
        :text="text.choose_manuf[language]"
      />
    </div>
    <div class="order__body">
      <Specification
        :order-price-type="orderPriceType"
        :order-data="orderData"
        @changePrice="changePrice()"
        :hide-header="true"
        :hide-footer="true"
        save-store="order/setData"
      />
    </div>
  </div>
</template>

<script>
import Specification from "@/pages/manufacture/specification/Specification.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/order.json";
import CalendarImage from "@/assets/images/order/calendar.svg";
import PartImage from "@/assets/images/order/part.svg";
import Select from "@/components/select/Select.vue";
import FileImage from "@/assets/images/order/file.svg";
import WeightImage from "@/assets/images/order/weight.svg";
import BoxImage from "@/assets/images/order/box.svg";
import CoinsImage from "@/assets/images/order/coins.svg";
import SuccessImage from "@/assets/images/order/success.svg";
import WarningImage from "@/assets/images/order/warning_medium.svg";
import Share from "@/assets/images/order/share.svg";
import Calendar from "@/components/calendar/Calendar.vue";
import DropdownV3 from "@/components/dropdownV3/DropdownV3.vue";
import { query } from "@/utilities/axios";
import { getNumberWithSpaces, getMoney } from "@/utilities/functions/index";
import Textarea from "@/components/textarea/Textarea.vue";
import Modal from "@/components/modal/Modal.vue";
import moment from "moment";
import Input from "@/components/input/Input.vue";
import SelectCompany from "./components/selectCompany/SelectCompany.vue";
import PackageJson from "../../../../package.json";
// import Dropdown from '@/components/dropdown/Dropdown.vue';
// import Export from '@/assets/images/order/export.svg';
// import { saveAs } from 'file-saver';

const KpModal = () => import("./components/kpModal/KpModal.vue");

export default {
  name: "Order",
  data() {
    return {
      text,
      PackageJson,
      path: this.$route.path.split("/")[1],
      getNumberWithSpaces,
      getMoney,
      orderStatus: "",
      orderName: "",
      orderData: null,
      showSendModal: false,
      closeDateApp: () => {},
      closeDateExec: () => {},
      timer: null,
      priceTimer: null,
      checkData: null,
      showSelectCompanyModal: false,
      showLinkModal: false,
      successCopy: false,
      orderCost: 0,
      priceRecommendations: null,
      recommendations: null,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      orderPriceType: 3,
      showKpModal: false,
      showRefuseModal: false,
      cancelReasons: [],
      refuseData: {},
      reasonError: null,
      textReasonError: null,
      kpData: null,
    };
  },
  computed: {
    type() {
      return this.$route.meta.type;
    },
    language() {
      return this.$store.state.index.language;
    },
    name() {
      return this.$store.state.order.name;
    },
    info() {
      return this.$store.state.order.info;
    },
    token() {
      const token = localStorage.getItem("token");
      return token;
    },
    access() {
      return this.$store.state.user.access;
    },
  },
  methods: {
    getOrder(uuid) {
      const headers = {};
      const token = localStorage.getItem("token");
      if (token) {
        headers.token = token;
      }
      query(
        "post",
        "getOrderInfoV2",
        {
          uuid,
        },
        headers,
      )
        .then((order) => {
          this.orderStatus = order.order_info.status;
          this.orderData = order.order_info;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$store.commit("index/setShow404", true);
          }
        });
    },
    goToEdit() {
      this.$router.push(`/create_order/${this.$route.params.id}`);
    },
    closeSendModal() {
      this.showSendModal = false;
    },
    openShowSendModal() {
      this.showSendModal = true;
    },
    setDateAppClose(func) {
      this.closeDateApp = func;
    },
    setDateExecClose(func) {
      this.closeDateExec = func;
    },
    saveDateExec(date) {
      const splitDate = date.split(".");
      const normalDate = new Date(+splitDate[2], +splitDate[1] - 1, splitDate[0]);
      this.saveData("date_execution", moment(normalDate).format("YYYY.MM.DD"));
      this.closeDateExec();
    },
    saveDateApp(date) {
      const splitDate = date.split(".");
      const normalDate = new Date(+splitDate[2], +splitDate[1] - 1, splitDate[0]);
      this.saveData("date_applications", moment(normalDate).format("YYYY.MM.DD"));
      this.closeDateApp();
    },
    changeOrder(name, value) {
      this.resetTimer(name, value);
    },
    startTimer(name, value) {
      this.timer = setTimeout(() => {
        this.saveData(name, value);
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(name, value) {
      this.stopTimer();
      this.startTimer(name, value);
    },
    startPriceTimer(value) {
      console.log(value);
      this.priceTimer = setTimeout(() => {
        this.changeOrderKPinfo({
          total_price: value,
        });
      }, 1000);
    },
    stopPriceTimer() {
      clearTimeout(this.priceTimer);
      this.priceTimer = null;
    },
    resetPriceTimer(value) {
      this.stopPriceTimer();
      this.startPriceTimer(value);
    },
    saveData(name, value) {
      const data = {
        uuid: this.$route.params.id,
      };
      data[name] = value;
      query("post", "changeOrderInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.getOrder(this.$route.params.id);
      });
    },
    downloadFiles() {
      /* query('post', 'getOrderArchive', {
        uuid: this.$route.params.id,
      }, {
        token: localStorage.getItem('token'),
      }).then((item) => {
        console.log(item);
        const blob = new Blob([item], { type: 'application/zip' });
        saveAs(blob, 'data.zip');
      }); */
    },
    changeOrderStatusToCheck() {
      query(
        "post",
        "changeOrderStatusToCheck",
        {
          uuid: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.showSendModal = false;
        this.getOrder(this.$route.params.id);
      });
    },
    openSelectCompanyModal() {
      this.showSelectCompanyModal = true;
    },
    closeSelectCompanyModal() {
      this.showSelectCompanyModal = false;
    },
    openAuth() {
      this.$store.commit("user/setShowAuthorizeModal", true);
    },
    changeAccessByLink(share) {
      query(
        "post",
        "changeAccessByLink",
        {
          uuid: this.$route.params.id,
          share: share,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.getOrder(this.$route.params.id);
      });
    },
    openLinkModal() {
      this.showLinkModal = true;
    },
    closeLinkModal() {
      this.showLinkModal = false;
    },
    copyText(text) {
      this.successCopy = true;
      setTimeout(() => {
        this.successCopy = false;
      }, 1500);
      navigator.clipboard.writeText(text);
    },
    getRecommendCost() {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token) {
          query(
            "post",
            "getOrderPriceRecommendations",
            {
              uuid: this.$route.params.id,
            },
            {
              token: token,
            },
          )
            .then((result) => {
              this.recommendations = result.order_info;
              this.priceRecommendations = result.order_info.price_recommendation;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    setRecommendPrice() {
      this.orderCost = this.priceRecommendations;
    },
    // устанавливаем
    setOrderPriceType(val) {
      this.orderPriceType = val;
      this.changeOrderKPinfo({
        id_order_estimation_formula: this.orderPriceType,
      }).then(() => {
        this.getKPInfo().then(() => {
          if (val === 1) {
            this.orderCost = this.priceRecommendations;
          } else if (val === 2) {
            this.orderCost = this.kpData.total_price ? this.kpData.total_price : 0;
          } else if (val === 3) {
            this.orderCost = this.kpData.total_price ? this.kpData.total_price : 0;
          }
        });
      });
    },
    openKpModal() {
      this.stopPriceTimer();
      this.changeOrderKPinfo({
        total_price: this.orderCost,
      }).then(() => {
        this.showKpModal = true;
      });
    },
    closeKpModal() {
      this.showKpModal = false;
      this.getOrder(this.$route.params.id);
    },
    refuseOrder() {
      let errorLength = 0;
      if (!this.refuseData.id_decline_order_reason) {
        this.reasonError = text.required_field[this.language];
        errorLength += 1;
      }
      if (errorLength === 0) {
        const data = {
          uuid: this.$route.params.id,
        };
        query(
          "post",
          "cancelOrder",
          { ...this.refuseData, ...data },
          {
            token: localStorage.getItem("token"),
          },
        ).then(() => {
          this.closeRefuseModal();
        });
      }
    },
    closeRefuseModal() {
      this.showRefuseModal = false;
    },
    openRefuseModal() {
      if (this.cancelReasons.length === 0) {
        query(
          "get",
          "getCancelOrderReasons",
          {},
          {
            token: localStorage.getItem("token"),
          },
        ).then((reasons) => {
          this.cancelReasons = reasons;
        });
      }
      this.showRefuseModal = true;
    },
    saveRefuseData(value, label) {
      this.refuseData[label] = value;
      this.reasonError = false;
      this.textReasonError = false;
    },
    seeOffers() {
      this.$router.push(`/order_offers/${this.$route.params.id}`);
    },
    getKPInfo() {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "getOrderKPinfo",
          {
            uuid: this.$route.params.id,
          },
          {
            token: localStorage.getItem("token"),
          },
        )
          .then((result) => {
            this.kpData = result;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeOrderKPinfo(addData) {
      return new Promise((resolve) => {
        let data = {
          uuid: this.$route.params.id,
        };
        data = { ...data, ...addData };
        query("post", "changeOrderKPinfo", data, {
          token: localStorage.getItem("token"),
        }).then((result) => {
          this.kpData = result;
          resolve();
        });
      });
    },
    checkOrder() {
      const uuid = this.$route.params.id;
      query(
        "post",
        "checkOrder",
        {
          uuid: uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((checkData) => {
        if (!checkData.check) {
          this.checkData = checkData;
          this.openShowSendModal();
        } else {
          this.$router.push(`/manufacturer_search/${uuid}`);
        }
      });
    },
    saveFullPrice(value) {
      this.orderCost = value;
      this.resetPriceTimer(value);
    },
    changePrice() {
      this.getKPInfo().then(() => {
        this.orderCost = this.kpData.total_price;
      });
    },
  },
  mounted() {
    const path = this.$route.path.split("/")[1];
    this.getOrder(this.$route.params.id);
    if (path !== "view-order") {
      Promise.all([this.getKPInfo(), this.getRecommendCost()]).then(() => {
        if (this.priceRecommendations && !(this.kpData && this.kpData.total_price)) {
          this.orderCost = this.priceRecommendations;
          this.orderPriceType = 1;
          this.changeOrderKPinfo({
            total_price: this.orderCost,
            id_order_estimation_formula: this.orderPriceType,
          });
        } else {
          this.orderCost = this.kpData.total_price ? this.kpData.total_price : 0;
          this.orderPriceType = this.kpData.id_order_estimation_formula
            ? this.kpData.id_order_estimation_formula
            : 3;
          this.changeOrderKPinfo({
            total_price: this.orderCost,
            id_order_estimation_formula: this.orderPriceType,
          });
        }
      });
    }
    // this.getKPInfo();
    // this.getRecommendCost();
    if (this.path) {
      this.$store.commit("user/setAfterAuthorize", () => {
        this.$router.push(`/order/${this.$route.params.id}`);
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem("token");
    const path = to.path.split("/")[1];
    if (token && path === "view-order") {
      next(`/order/${to.params.id}`);
    } else {
      next();
    }
  },
  components: {
    Textarea,
    Button,
    Specification,
    CalendarImage,
    PartImage,
    FileImage,
    WeightImage,
    BoxImage,
    WarningImage,
    // Dropdown,
    Calendar,
    DropdownV3,
    // Export,
    Modal,
    Input,
    SuccessImage,
    SelectCompany,
    Select,
    Share,
    KpModal,
    CoinsImage,
  },
};
</script>
